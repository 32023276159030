import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScrollingService {
  private sectionSource = new Subject<string>();
  currentSection$ = this.sectionSource.asObservable();
  private opacitySource = new Subject<number>();
  currentOpacity$ = this.opacitySource.asObservable();

  changeSection(section: string) {
    this.sectionSource.next(section);
  }

  
  setOpacityValue(value: number) {
    this.opacitySource.next(value);
  }
}