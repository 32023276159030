import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  private router = inject(Router);

  navigateToHome(): void {
    this.router.navigate([''], { fragment: 'video-container' });
  }

}
