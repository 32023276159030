import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import KeenSlider, { KeenSliderInstance } from 'keen-slider';

export interface Reference {
  text: string,
  source?: string,
  sourceText: string
}

@Component({
  selector: 'app-references',
  templateUrl: './references.component.html',
  styleUrls: ['./references.component.scss', '../../../node_modules/keen-slider/keen-slider.min.css']
})
export class ReferencesComponent implements OnDestroy {

  references: Reference[] = [
    {
      sourceText: 'SEEBURGER AG',
      text: "Sudden Inspiration haben 2022 auf unserer Sommerparty „SEEBURGER BBQ“ gespielt und dazu beigetragen, dass wir uns noch heute an diesen Abend erinnern! Musikalisch unheimlich gut und eine perfekte Songauswahl für solch ein Event. Der Satz: „So eine gute Coverband habe ich noch nie gehört“ fiel mehr als einmal. Wenn wir mal wieder nach einer Band Ausschau halten, wird es schwer sein, an Sudden Inspiration vorbeizukommen!"
    },
    {
      sourceText: 'Aldiana Club Andalusien',
      text: 'Die Musikalität und Bühnenpräsenz hat unsere Hotelgäste restlos beeindruckt. Das Repertoire war vielfältig und perfekt auf jeden Mottoabend abgestimmt - Musik für alle Altersgruppen auf höchstem Niveau, immer authentisch und professionell! Jederzeit herzlich willkommen im Club!',
    },
    {
      text: "Diese Coverband aus Bretten hat eine besondere Gabe, eine unvergessliche Atmosphäre zu schaffen und die Menschen zum Tanzen und zum Feiern zu bringen. Als main act war sie der Höhepunkt beim Primtalsommer 2022 bei uns in Spaichingen. Mit ihrer coolen Präsenz und ihrer musikalischen Vielseitigkeit lieferten sie immer wieder erstklassige Musik. Wenn ihr nach einer Band sucht, die eure"
        + " Veranstaltung zu einem unvergesslichen Erlebnis macht, dann seid ihr bei Sudden Inspiration genau"
        + "richtig! Vielen herzlichen"
        + " Dank für einen unvergesslichen Abend!",
      sourceText: "Stadtverwaltung Spaichingen"
    },
    {
      source: 'https://bnn.de/kraichgau/bretten/rio-rockt-ein-allerletztes-mal-keine-fortsetzung-fuer-musikevent',
      sourceText: 'BNN über „RioRockt 2022“',
      text: 'und schon sind die acht von „Sudden Inspiration“ in ihrem Element, verausgaben sich vom ersten Moment ihrer an Power kaum zu überbietenden Bühnenpräsenz, hüpfen, springen, animieren. Treibende Beats, unterstützt von Blech-Attacken der Bläser-Sektion. Jetzt gibt es im Publikum kein Halten mehr. Lauthals wird mitgesungen, getanzt sowieso und im hinteren Bereich der Halle können sich selbst ruhigere Besucher ein Mitwippen nicht verkneifen.'
    },
    {
      source: 'https://bnn.de/kraichgau/bretten/benefiz-konzert-ukraine-bretten-hendrik-boettcher-abend-voller-emotionen',
      sourceText: 'BNN über „Bretten für Ukraine“',
      text: 'Den Headliner des Abends „Sudden Inspiration“ als Coverband zu bezeichnen, wird der Musik nicht gerecht. Zwischen Klassikern aus Pop, Wave und Disco manövriert das Quartett stilsicher, auch kurz vor Mitternacht.'
    },
    {
      source: 'https://www.sueddeutsche.de/muenchen/fuerstenfeldbruck/fuerstenfeldbruck-fuerstenfeldbruck-wird-zur-fussgaengerzone-1.5627630',
      sourceText: 'Süddeutsche Zeitung über „Altstadtfest Fürstenfeldbruck“',
      text: 'Am anderen Ende der Straße gewinnt die Gruppe "Sudden Inspiration" verschiedenste Alterskategorien für sich, indem es ihr gelingt, Cover-Songs originell zu gestalten und gleichzeitig mit musikalischem Talent zu überzeugen.'
    },
    {
      source: 'https://www.saarbruecker-zeitung.de/saarland/saar-pfalz-kreis/homburg/perfekter-neustart-fuer-querbeat-reihe_aid-70911133',
      sourceText: 'Saarbrückener Zeitung über „Querbeat Homburg“',
      text: 'So bunt und gut ging es durch den Abend. Und mit jedem Song steigerte sich der Applaus der Querbeat-Fans auf dem Marktplatz.'
    },


  ];

  currentSlide: number = 0;

  myPlugin = (slider: any) => {
    let timeout: ReturnType<typeof setTimeout>;
    let mouseOver = false;

    function clearNextTimeout() {
      clearTimeout(timeout);
    }

    function nextTimeout() {
      clearTimeout(timeout);
      if (mouseOver) return;
      timeout = setTimeout(() => {
        slider.next();
      }, 5000);
    }

    slider.on('created', () => {
      slider.container.addEventListener('mouseover', () => {
        mouseOver = true;
        clearNextTimeout();
      });
      slider.container.addEventListener('mouseout', () => {
        mouseOver = false;
        nextTimeout();
      });
      nextTimeout();
    });
    slider.on('dragStarted', clearNextTimeout);
    slider.on('animationEnded', nextTimeout);
    slider.on('updated', nextTimeout);
  };

  ngAfterViewInit() {
    setTimeout(() => {

      this.slider = new KeenSlider(
        // @ts-ignore
        this.sliderRef.nativeElement, {
          loop: true,
          initial: this.currentSlide,
          slideChanged: (s) => {
            this.currentSlide = s.track.details.rel;
          },
          defaultAnimation: {
            duration: 750,
            easing: t => t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t,
          },
        },
        [this.myPlugin],
        );
        this.dotHelper = [
          ...Array(this.slider.track.details.slides.length).keys(),
        ]
    });
  }

  @ViewChild("sliderRef") sliderRef: ElementRef<HTMLElement> | undefined

  dotHelper: Array<Number> = []
  slider!: KeenSliderInstance

  interval: any = null

  ngOnDestroy() {
    if (this.slider) {
      this.slider.destroy()
    }
    clearInterval(this.interval);
  }

}
