import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import KeenSlider, { KeenSliderInstance } from 'keen-slider';

@Component({
  selector: 'app-images',
  templateUrl: './images.component.html',
  styleUrls: ['./images.component.scss', '../../../node_modules/keen-slider/keen-slider.min.css']
})
export class ImagesComponent implements OnInit {

  @Input() type!: 'landscape' | 'portrait';
  portraitGalleryImages: string[] = [];
  landscapeGalleryImages: string[] = [];
  private maxPortraitImage = 23;
  private maxLandscapeImage = 31;
  landscapeLoaded: boolean[] = [true, true];
  portraitLoaded: boolean[] = [true, true];

  @ViewChild('portraitSliderRef') portraitSliderRef: ElementRef<HTMLElement> | undefined;
  @ViewChild('landscapeSliderRef') landscapeSliderRef: ElementRef<HTMLElement> | undefined;

  currentPortraitSlide: number = 0;
  currentLandscapeSlide: number = 0;
  portraitSlider: KeenSliderInstance | null = null;
  landscapeSlider: KeenSliderInstance | null = null;

  myPlugin = (slider: any) => {
    let timeout: ReturnType<typeof setTimeout>;
    let mouseOver = false;

    function clearNextTimeout() {
      clearTimeout(timeout);
    }

    function nextTimeout() {
      clearTimeout(timeout);
      if (mouseOver) return;
      timeout = setTimeout(() => {
        slider.next();
      }, 5000);
    }

    slider.on('created', () => {
      slider.container.addEventListener('mouseover', () => {
        mouseOver = true;
        clearNextTimeout();
      });
      slider.container.addEventListener('mouseout', () => {
        mouseOver = false;
        nextTimeout();
      });
      nextTimeout();
    });
    slider.on('dragStarted', clearNextTimeout);
    slider.on('animationEnded', nextTimeout);
    slider.on('updated', nextTimeout);
  };

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.type === 'portrait') {
        this.portraitSlider = new KeenSlider(
          // @ts-ignore
          this.portraitSliderRef.nativeElement, {
          loop: true,
          initial: this.currentPortraitSlide,
          animationStarted: (s) => {
            const idx = s.track.details.rel + 1;
            this.portraitLoaded[idx + 1] = true;
            this.portraitLoaded[idx + 2] = true;
            this.portraitLoaded[(idx - 1) < 0 ? s.track.details.length : idx - 1] = true;
            this.portraitLoaded[(idx - 2) < 0 ? s.track.details.length - 1 : idx - 2] = true;
          },
          animationEnded: (s) => {
            const idx = s.track.details.rel;
            this.portraitLoaded[idx] = true;
          },
          slideChanged: (s) => {
            this.currentPortraitSlide = s.track.details.rel;
          },
          defaultAnimation: {
            duration: 1000,
            easing: t => t,
          },
        },
          [this.myPlugin]);
      }
      if (this.type === 'landscape') {
        this.landscapeSlider = new KeenSlider(
          // @ts-ignore
          this.landscapeSliderRef.nativeElement, {
          loop: true,
          initial: this.currentLandscapeSlide,
          slideChanged: (s) => {
            this.currentLandscapeSlide = s.track.details.rel;
          },
          animationStarted: (s) => {
            const idx = s.track.details.rel;
            this.landscapeLoaded[idx + 1] = true;
            this.landscapeLoaded[idx + 2] = true;
            this.landscapeLoaded[(idx - 1) < 0 ? s.track.details.length : idx - 1] = true;
            this.landscapeLoaded[(idx - 2) < 0 ? s.track.details.length - 1 : idx - 2] = true;
          },
          animationEnded: (s) => {
            const idx = s.track.details.rel;
            this.landscapeLoaded[idx] = true;
          },
          defaultAnimation: {
            duration: 1000,
            easing: t => t,
          },
        },
          [this.myPlugin]);
      }
    });
  }

  ngOnDestroy() {
    if (this.landscapeSlider) this.landscapeSlider.destroy();
    if (this.portraitSlider) this.portraitSlider.destroy();
  }

  ngOnInit(): void {
    this.landscapeLoaded[this.maxLandscapeImage] = true; // always load the last image as well
    this.portraitLoaded[this.maxPortraitImage] = true; // always load the last iamge as well
    for (let i = 0; i <= this.maxLandscapeImage; i++) {
      this.landscapeGalleryImages.push(`assets/images/big/landscape/${i}.webp`);
    }
    for (let i = 0; i <= this.maxPortraitImage; i++) {
      this.portraitGalleryImages.push(`assets/images/big/portrait/${i}.webp`);
    }
    this.shuffleArray(this.landscapeGalleryImages);
    this.shuffleArray(this.portraitGalleryImages);
  }

  private shuffleArray(array: any[]) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
  }

  scaleLandscapeStyle(i: number) {
    if (this.landscapeLoaded[i]) {
      return this.landscapeGalleryImages[i];
    }
    return '';
  }

  scalePortraitStyle(i: number) {
    if (this.portraitLoaded[i]) {
      return this.portraitGalleryImages[i];
    }
    return '';
  }
}
