import {Injectable, inject} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import sha256 from 'crypto-js/sha256';
import {ReCaptchaV3Service} from 'ng-recaptcha';
import {Observable, switchMap} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  baseUrl = environment.baseUrl;
  httpClient = inject(HttpClient);

  submitForm(service: ReCaptchaV3Service, date: any, contact: string, email: any, eventAddress?: string, phone?: string, playingTime?: string): Observable<string> {
    return service.execute('submitForm').pipe(switchMap(token => {
      const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');

      const encodedHash = sha256(email);
      const hash = encodedHash.toString();

      return this.httpClient.post(`${this.baseUrl}/form`, {
        date,
        contact,
        email,
        eventAddress,
        phone,
        playingTime,
        hash,
        token
      }, {headers, responseType: 'text'});
    }));
  }
}
