import { Component, Injector, OnDestroy, inject, runInInjectionContext } from '@angular/core';
import { FormGroupDirective, NgForm, Validators, FormControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { ContactService } from '../services/contact.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnDestroy {
  emailFormControl = new FormControl('', [Validators.required, Validators.email]);

  matcher = new MyErrorStateMatcher();
  selectedEventType: string = '';
  selectedEventLocationType = '';
  isConsentGranted = false;

  minDate: Date;
  date: any;
  eventAddress: string | undefined = '';
  playingTime: string | undefined = undefined;
  guestAmount: string | undefined = undefined;
  eventLocation: string | undefined = undefined;
  contact: string | undefined = undefined;
  phone: string | undefined = undefined;
  public notSent: boolean = true;
  public loading = false;
  public error = false;
  private service: ReCaptchaV3Service | undefined = undefined;
  private readonly injector = inject(Injector);

  constructor(private contactService: ContactService) {
    this.minDate = new Date();
  }

  ngOnDestroy(): void {
    if (this.service) {
      let element = document.getElementsByClassName('grecaptcha-badge');
      element[0].setAttribute('id', 'grecaptcha_badge');
      const doc = document.getElementById('grecaptcha_badge')
      if (doc) {
        doc.style.display = 'none';
      }
      
    }
  }

  onConsent() {
    this.isConsentGranted = true;
    runInInjectionContext(this.injector, () => {
      this.service = inject(ReCaptchaV3Service)
    });
  }

  submit() {
    if (
      this.date &&
      this.emailFormControl.valid &&
      this.contact
    ) {
      this.notSent = false;
      this.loading = true;
      this.contactService.submitForm(this.service!, this.date, this.contact, this.emailFormControl.value,
        this.eventAddress, this.phone, this.playingTime).subscribe(ret => {
          console.log(ret);
          this.loading = false;
          this.error = !ret.includes('success');
        }, error => {
          console.error(error);
          this.loading = false;
          this.error = true;
        });
    } else {
      console.log('not valid');
    }
  }
}
