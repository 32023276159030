<div class="flex-container">
  <div class="max-width-container">
    <div class="about-us-wrapper">
      <div class="content-header">Über uns</div>
      <div class="stats-counter-wrapper">
        <div class="individual-stat-wrapper">
          <app-stats-counter [initialValue]="0" [finalValue]="16"></app-stats-counter>
          <div>Jahre Banderfahrung</div>
        </div>
        <div class="individual-stat-wrapper">
          <app-stats-counter [initialValue]="0" [finalValue]="8" [prefixText]="'bis zu'"></app-stats-counter>
          <div>Musiker*innen bei Ihrer Veranstaltung</div>
        </div>
        <div class="individual-stat-wrapper">
          <app-stats-counter [initialValue]="0" [finalValue]="150" [prefixText]="'über'"></app-stats-counter>
          <div>Songs im Repertoire</div>
        </div>
      </div>
      <div class="secondary-header">
        Coverband der Extraklasse - Musik für alle Generationen
      </div>
      <div class="first-text">
        Ausgezeichnet als "beste Coverband" aus dem Raum Karlsruhe (die neue welle), blicken wir mittlerweile auf 16 Jahre Live-Erfahrung zurück und haben immer noch nicht genug!<br>
        Entertainment, Leidenschaft und ein Repertoire aus aktuellen Rock-, Pop- und Funkcharts und gefeierten Evergreens auf einer Bühne!
      </div>
      <div class="second-text">
        Egal ob Stadtfest, Firmenfest oder Hochzeit, wir finden gemeinsam die beste Besetzung für Ihr Event!
      </div>

      <mat-accordion displayMode="flat" multi="true" class="expansion-panel">
        <!-- For 4 members -->
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title class="expansion-panel-title">
              Grundbesetzung
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="member-grid four-members-grid">
            <app-member-card *ngFor="let member of fourMembers" [member]="member">
            </app-member-card>
          </div>
        </mat-expansion-panel>

        <!-- For 5 members -->
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title class="expansion-panel-title">
              + Sängerin
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="member-grid one-members-grid">
            <app-member-card *ngFor="let member of fiveMembers" [member]="member">
            </app-member-card>
          </div>
        </mat-expansion-panel>

        <!-- For 8 members -->
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title class="expansion-panel-title">
              + Bläsersatz
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="member-grid three-members-grid">
            <app-member-card *ngFor="let member of eightMembers" [member]="member">
            </app-member-card>
          </div>
        </mat-expansion-panel>

      </mat-accordion>


    </div>
  </div>
</div>