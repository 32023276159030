import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Gig} from '../gigs/gigs.component';

@Injectable({
  providedIn: 'root'
})
export class GigsService {

  constructor(private httpClient: HttpClient) {
  }

  getGigs(): Observable<Gig[]> {
    return this.httpClient.get<Gig[]>('https://rest.bandsintown.com/artists/fbid_142797175764673/events/?app_id=2c1acb65f3ebbe558e2a760e37367be1');
  }

  getPastGigs(): Observable<Gig[]> {
    return this.httpClient.get<Gig[]>('https://rest.bandsintown.com/artists/fbid_142797175764673/events/?app_id=2c1acb65f3ebbe558e2a760e37367be1&date=past');
  }
}
