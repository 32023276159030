<nav>
  <mat-toolbar class="my-toolbar">
    <mat-toolbar-row>
      <div class="toolbar-content">
        <button mat-button class="logo-button" [ngClass]="{'active': currentSection === 'video-container'}"
          (click)="navigateTo('','video-container')">
          <img src="/assets/icons/white.svg" class="logo" alt="band-logo">
        </button>
        <button mat-flat-button class="full-size-toolbar toolbar-button"
          [ngClass]="{'active': currentSection === 'references'}" (click)="navigateTo('', 'references')">
          Referenzen
        </button>
        <button mat-flat-button class="full-size-toolbar toolbar-button"
          [ngClass]="{'active': currentSection === 'about-us'}" (click)="navigateTo('', 'about-us')">
          Über uns
        </button>
        <button mat-flat-button class="full-size-toolbar toolbar-button"
          [ngClass]="{'active': currentSection === 'gigs'}" (click)="navigateTo('', 'gigs')">
          Termine
        </button>
        <button mat-flat-button class="full-size-toolbar toolbar-button special-menu-button"
          [matMenuTriggerFor]="mediaMenu">
          <fa-icon [icon]="faCaretDown" class="icon"></fa-icon> Media
        </button>

        <!-- Menu Dropdown -->
        <mat-menu #mediaMenu="matMenu" class="nav-menu">
          <!-- <button mat-menu-item class="mobile-menu-button" (click)="navigateTo('videos')">Videos</button>
          <button mat-menu-item class="mobile-menu-button" (click)="navigateTo('photos')">Photos</button> -->
          <button mat-menu-item class="mobile-menu-button" (click)="navigateTo('veranstalter')">Veranstalter</button>
        </mat-menu>

        <span class="example-spacer"></span>
        <button mat-raised-button class="book-now-navigation" routerLink="form">
          <span>Jetzt buchen</span>
        </button>
        <button mat-icon-button class="example-icon mobile-toolbar" aria-label="Example icon-button with menu icon"
          [matMenuTriggerFor]="menu">
          <fa-icon [icon]="faBars" class="menu-icon"></fa-icon>
        </button>
        <mat-menu #menu="matMenu" class="nav-menu">
          <button mat-button class="mobile-menu-button" (click)="navigateTo('','references')">
            Referenzen
          </button>
          <button mat-button class="mobile-menu-button" (click)="navigateTo('','about-us')">
            Über uns
          </button>
          <button mat-button class="mobile-menu-button" (click)="navigateTo('','gigs')">
            Termine
          </button>
          <mat-divider class="dark-divider"></mat-divider>
          <!-- <button mat-button class="mobile-menu-button" (click)="navigateTo('videos')">Videos</button>
          <button mat-button class="mobile-menu-button" (click)="navigateTo('photos')">Photos</button> -->
          <button mat-button class="mobile-menu-button" (click)="navigateTo('veranstalter')">Veranstalter</button>
        </mat-menu>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
</nav>
<router-outlet></router-outlet>
<app-footer>
</app-footer>