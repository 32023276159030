<div id="video-container" class="video-container">
  <video #bgVideo [muted]="true" [playsInline]="true" class="my-video" [autoplay]="true" [loop]="true">
    <source src="/assets/videos/output_1080p.mp4" media="(min-width: 1280px)" type="video/mp4">
    <source src="/assets/videos/output_720p.mp4" media="(min-width: 960px)" type="video/mp4">
    <source src="/assets/videos/output_540p.mp4" media="(min-width: 640px)" type="video/mp4">
    <source src="/assets/videos/output_360p.mp4" type="video/mp4">
    Your browser does not support the video tag.
  </video>
  <div class="overlay-button-wrapper">
    <div class="overlay-button-container">
      <button mat-flat-button class="overlay-button" (click)="openTrailerDialog()">
        <fa-icon [icon]="faPlay" class="play-button-icon"></fa-icon>
        <span class="trailer-text">Watch the trailer</span>
      </button>
    </div>
  </div>
</div>

<div id="references" class="my-card white-card">
  <div class="flex-wrap-container">
    <app-references class="flex-child"></app-references>
    <div class="flex-child">
      <app-images [type]="'landscape'" class="width-images"></app-images>
    </div>
  </div>
</div>
<div class="white-to-grey"></div>

<div id="about-us" class="my-card grey-card">
  <app-about-us></app-about-us>
</div>
<div class="grey-to-white"></div>

<div id="gigs" class="my-card white-card">
  <div class="flex-wrap-container">
    <div class="other-flex-child">
      <app-images [type]="'portrait'" class="gigs-image"></app-images>
    </div>
    <app-gigs class="other-flex-child"></app-gigs>
  </div>
</div>