import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild, inject, Renderer2, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { TrailerDialogComponent } from '../trailer-dialog/trailer-dialog.component';
import { ScrollingService } from '../services/scroll.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements AfterViewInit, OnDestroy, OnInit {

  private observer!: IntersectionObserver;

  private el = inject(ElementRef);
  private scrollService = inject(ScrollingService);

  faPlay = faPlay;

  apiLoaded = false;
  @ViewChild('bgVideo') bgVideo!: ElementRef;
  videoWidth: number = 0;

  sections = ['video-container', 'references', 'about-us', 'gigs'];

  // Track the current active section
  currentSection = '';

  constructor(public dialog: MatDialog, private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.fragment.subscribe(fragment => { 
      setTimeout(() => this.scrollToElement(fragment), 150); // You can adjust the delay time as necessary
    });
  }

  scrollToElement(elementId: string | null): void {
    if (elementId) {
      const element = document.getElementById(elementId);
      if (element) {
        // Calculate the position you want to scroll to
        const yOffset = -72; // Your fixed header height here
        const y = element.getBoundingClientRect().top + window.scrollY + yOffset;

        // Now, use window.scrollTo with the calculated position and smooth scrolling
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    }
  }

  handleIntersect(entries: IntersectionObserverEntry[]) {
    const [entry] = entries;
    // entry.intersectionRatio gives the percentage of the element that is currently visible
    // You can use this to determine the opacity level of the header background

    const opacity = 1 - entry.intersectionRatio; // This is just an example, adjust as needed
    this.scrollService.setOpacityValue(opacity);
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    // Current scroll position
    const scrollPosition = window.scrollY || window.pageYOffset;

    // Viewport height
    const viewportHeight = window.innerHeight;

    // Middle of the viewport
    const middleOfViewport = scrollPosition + (viewportHeight / 2);

    let currentSection = '';

    for (const section of this.sections) {
      const element = document.getElementById(section);
      if (element) {
        // Element's top and bottom relative to the viewport
        const bounding = element.getBoundingClientRect();

        // Element's absolute positions from the document top
        const elementTop = bounding.top + scrollPosition;
        const elementBottom = bounding.bottom + scrollPosition;

        // Check if the middle of the viewport is within the element's bounds
        if (middleOfViewport >= elementTop && middleOfViewport <= elementBottom) {
          currentSection = section;
          break;  // If we've found the section, no need to continue looping
        }
      }
    }
    this.currentSection = currentSection;
    this.scrollService.changeSection(this.currentSection);
  }

  openTrailerDialog(): void {
    const viewportWidth = window.innerWidth;
    const maxWidth = viewportWidth - 4 * 10;
    let width = maxWidth;

    // If viewport is wider than 600px, set width to 80vw
    if (viewportWidth > 600) {
      width = (viewportWidth / 10 * 8);
    }

    const height = (9 / 16) * width; // Calculate height based on the 16:9 ratio

    this.bgVideo.nativeElement.pause();

    const dialogRef = this.dialog.open(TrailerDialogComponent, {
      width: width + "px",
      height: height + "px",
      maxWidth: "100%",
      enterAnimationDuration: 500,
      backdropClass: 'dark-dialog-backdrop',
      panelClass: 'dark-dialog-container'
    });

    dialogRef.afterClosed().subscribe(() => this.bgVideo.nativeElement.play());
  }

  ngAfterViewInit() {
    this.observer = new IntersectionObserver(this.handleIntersect.bind(this), {
      threshold: [0, 0.25, 0.5, 0.75, 1] // These thresholds represent the percentage of the target's visibility
    });

    const videoElem = this.el.nativeElement.querySelector('.my-video');
    this.observer.observe(videoElem);
  }

  ngOnDestroy() {
    this.scrollService.setOpacityValue(1);
    this.scrollService.changeSection('');
    if (this.observer) {
      this.observer.disconnect();
    }
  }
}
