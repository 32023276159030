import { AfterViewInit, Component, ElementRef, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-stats-counter',
  templateUrl: './stats-counter.component.html',
  styleUrls: ['./stats-counter.component.scss']
})
export class StatsCounterComponent implements OnInit, AfterViewInit {
  @Input() initialValue: number = 0;
  @Input() finalValue: number = 0;
  @Input() prefixText: string = '';
  displayedValue: number = 0;

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    this.displayedValue = this.initialValue;
  }

  ngAfterViewInit(): void {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.animateCounting();
          observer.unobserve(this.el.nativeElement);
        }
      });
    }, options);

    observer.observe(this.el.nativeElement);
  }

  animateCounting(): void {
    let start = this.initialValue;
    const end = this.finalValue;
    const duration = 2000; // duration in milliseconds
    const stepTime = Math.abs(Math.floor(duration / (end - start)));

    let timer = setInterval(() => {
      start++;
      this.displayedValue = start;
      if (start === end) clearInterval(timer);
    }, stepTime);
  }
}
