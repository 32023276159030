import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { FormComponent } from './form/form.component';
import { GigsComponent } from './gigs/gigs.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { FooterComponent } from './footer/footer.component';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { HttpClientModule } from '@angular/common/http';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImagesComponent } from './images/images.component';
import { SocialComponent } from './social/social.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BioComponent } from './bio/bio.component';
import { DownloadsComponent } from './downloads/downloads.component';
import { ContactComponent } from './contact/contact.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatListModule } from '@angular/material/list';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { ReferencesComponent } from './references/references.component';
import { ReferenceComponent } from './reference/reference.component';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AboutUsComponent } from './about-us/about-us.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatPaginatorIntlGerman } from './services/mat-paginator-intl-german.service';
import { TrailerDialogComponent } from './trailer-dialog/trailer-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { StatsCounterComponent } from './stats-counter/stats-counter.component';
import { MatCardModule } from '@angular/material/card';
import { MemberCardComponent } from './member-card/member-card.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FormComponent,
    GigsComponent,
    ImpressumComponent,
    FooterComponent,
    ImagesComponent,
    SocialComponent,
    BioComponent,
    DownloadsComponent,
    ContactComponent,
    ReferencesComponent,
    ReferenceComponent,
    AboutUsComponent,
    TrailerDialogComponent,
    StatsCounterComponent,
    MemberCardComponent
  ],
  imports: [
    BrowserModule,
    RouterModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    YouTubePlayerModule,
    MatDatepickerModule,
    HttpClientModule,
    MatNativeDateModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    MatTabsModule,
    MatSidenavModule,
    MatFormFieldModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatListModule,
    MatInputModule,
    MatButtonModule,
    RecaptchaV3Module,
    MatToolbarModule,
    MatMenuModule,
    MatExpansionModule,
    MatPaginatorModule,
    MatDialogModule,
    MatCardModule,
  ],
  providers: [
    MatDatepickerModule, { provide: MAT_DATE_LOCALE, useValue: 'ge' }, {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: '6Lc9dYAiAAAAAGjGn-WM5SrgTDhLuYmDsH2LUURH'
    }, { provide: MatPaginatorIntl, useClass: MatPaginatorIntlGerman }],
  bootstrap: [AppComponent]
})
export class AppModule {
}
