<div class="top-spacer"></div>
<div class="max-width-container">
  <div class="my-content">
    <mat-card class="download-card" *ngFor="let file of files">
      <!-- Removed mat-card-header and used mat-card-title directly -->
      <mat-card-title class="centered-title">{{file.title}}</mat-card-title>
      <mat-card-content class="text-align-center">
        <p>{{file.description}}</p>
      </mat-card-content>
      <mat-card-actions>
        <button mat-button>
          <a [href]="staticUrl + file.url" target="_blank">Download</a>
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>