import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ImpressumComponent} from './impressum/impressum.component';
import {FormComponent} from './form/form.component';
import {HomeComponent} from './home/home.component';
import { DownloadsComponent } from './downloads/downloads.component';

const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'form', component: FormComponent},
  {path: 'impressum', component: ImpressumComponent},
  {path: 'veranstalter', component: DownloadsComponent},
  {path: 'videos', component: DownloadsComponent},
  {path: 'photos', component: DownloadsComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
