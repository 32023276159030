<div class="top-spacer"></div>
<div class="impressum-container">
  <div class="margin-container max-width-container">
    <div class="impressum-card">
      <div>
        <div class="small-card">
          <div class="content-header">
            Angaben gemäß § 5 TMG
          </div>
          <div>
            Merl Link Musik GbR <br>
            Talblick 3 <br>
            75015 Bretten <br>
            <a href="mailto:mail@suddeninspiration.net">mail@suddeninspiration.net</a>
          </div>
        </div>
        <div class="small-card">
          <h3>
            Vertreten durch
          </h3>
          <div>
            Julian Merl <br>
            Dennis Link
          </div>
        </div>
      </div>
      <div fxLayout.gt-sm="column" fxLayoutAlign.gt-sm="space-between start" fxFlex.gt-sm="50" fxLayoutGap.gt-sm="16px">
        <div class="small-card">
          <h3>
            Booking
          </h3>
          <div>
            Julian Merl <br>
            Dennis Link <br>
            <a href="mailto:booking@suddeninspiration.net">booking@suddeninspiration.net</a>
          </div>
        </div>
        <div class="small-card">
          <h3>
            Buchhaltung
          </h3>
          <div>
            DE299950727 <br>
            Fragen zu Rechnungen <br>
            <a href="mailto:billing@suddeninspiration.net">billing@suddeninspiration.net</a>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="content-header">
        Haftungsausschluss
      </div>
      <div>
        <h2>Haftung für Inhalte</h2>
        <p>
          Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und
          Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1
          TMG
          für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind
          wir
          als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
          überwachen
          oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur
          Entfernung
          oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine
          diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich.
          Bei
          Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
        </p>
        <h2>Haftung für Links</h2>
        <p>
          Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben.
          Deshalb
          können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist
          stets
          der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt
          der
          Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung
          nicht
          erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte
          einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links
          umgehend entfernen
        </p>
        <h2>Urheberrecht</h2>
        <p>
          Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen
          Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen
          des
          Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und
          Kopien
          dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser
          Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden
          Inhalte
          Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden,
          bitten
          wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte
          umgehend entfernen.
        </p>
        <h2>Datenschutz</h2>
        <p>
          Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf unseren
          Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder eMail-Adressen) erhoben werden, erfolgt
          dies,
          soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an
          Dritte weitergegeben.

          Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail)
          Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
          möglich.

          Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur Übersendung von
          nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit ausdrücklich widersprochen.
          Die
          Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von
          Werbeinformationen, etwa durch Spam-Mails, vor.
        </p>
        <p>Quellen:&nbsp;<span style="text-decoration:underline;"><a
              href="https://www.e-recht24.de/muster-disclaimer.htm" target="_blank">eRecht24
              Disclaimer</a></span>,&nbsp;<span style="text-decoration:underline;">&nbsp;<span
              style="text-decoration:underline;"><a href="https://www.google.com/intl/de/+/policy/+1button.html"
                target="_blank">Datenschutzerklärung Google +1</a></span></span>
        </p>
      </div>
    </div>
  </div>
</div>