import { Component, Input } from '@angular/core';
import { BandMember } from '../about-us/about-us.component';

@Component({
  selector: 'app-member-card',
  templateUrl: './member-card.component.html',
  styleUrls: ['./member-card.component.scss']
})
export class MemberCardComponent {
  @Input() member!: BandMember
}
