import {Component, Input, OnInit} from '@angular/core';
import {Reference} from '../references/references.component';

@Component({
  selector: 'app-reference',
  templateUrl: './reference.component.html',
  styleUrls: ['./reference.component.scss']
})
export class ReferenceComponent implements OnInit {

  @Input()
  reference: Reference | undefined;

  constructor() {
  }

  ngOnInit(): void {
  }

}
