import {Component, OnInit} from '@angular/core';
import {faFacebook, faInstagram, faYoutube} from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-social',
  templateUrl: './social.component.html',
  styleUrls: ['./social.component.scss']
})
export class SocialComponent implements OnInit {

  faFacebook = faFacebook;
  faInstagram = faInstagram;
  faYoutube = faYoutube;

  ngOnInit(): void {
  }
}
