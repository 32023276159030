<div *ngIf="type === 'portrait'" class="portrait-parent">
  <div class="navigation-wrapper">
    <div class="keen-slider keen-slider--vertical" #portraitSliderRef>
      <div *ngFor="let image of portraitGalleryImages; let i = index">
        <div class="keen-slider__slide number-slide1">
          <img [src]="scalePortraitStyle(i)" alt="portraitImage" class="portrait-image">
        </div>
      </div>
    </div>
    <svg [ngClass]="'arrow arrow--left ' + (currentPortraitSlide === 0 ? 'arrow--disabled' : '')"
         (click)="portraitSlider?.prev()"
         xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 24 24">
      <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"/>
    </svg>
    <svg *ngIf="portraitSlider"
         [ngClass]="'arrow arrow--right ' +
      (portraitSlider.track.details.slides.length - 1 === currentPortraitSlide
        ? 'arrow--disabled'
        : '')"
         (click)="portraitSlider.next()"
         xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 24 24">
      <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"/>
    </svg>
  </div>
</div>
<div *ngIf="type === 'landscape'" class="landscape-parent">
  <div class="navigation-wrapper">
    <div class="keen-slider keen-slider--vertical" #landscapeSliderRef>
      <div *ngFor="let image of landscapeGalleryImages; let i = index">
        <div class="keen-slider__slide number-slide1">
          <img [src]="scaleLandscapeStyle(i)" alt="landscapeImage" class="landscape-image">
        </div>
      </div>
    </div>
    <svg [ngClass]="'arrow arrow--left ' + (currentLandscapeSlide === 0 ? 'arrow--disabled' : '')"
         (click)="landscapeSlider?.prev()"
         xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 24 24">
      <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"/>
    </svg>
    <svg *ngIf="landscapeSlider"
         [ngClass]="'arrow arrow--right ' +
      (landscapeSlider.track.details.slides.length - 1 === currentLandscapeSlide
        ? 'arrow--disabled'
        : '')"
         (click)="landscapeSlider.next()"
         xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 24 24">
      <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"/>
    </svg>
  </div>
</div>
