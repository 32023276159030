import { Component, ElementRef, HostListener, OnInit, Renderer2, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faBars, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { ScrollingService } from './services/scroll.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  private el = inject(ElementRef);
  private renderer = inject(Renderer2);
  private router = inject(Router);
  private scrollService = inject(ScrollingService);
  currentSection: string = '';

  constructor(private route: ActivatedRoute) { }

  title = 'Sudden Inspiration';

  faBars = faBars;
  faCaretDown = faCaretDown;

  lastScrollTop: number = 0;

  ngOnInit() {
    this.scrollService.currentOpacity$.subscribe(opacity => {
      const toolbarElem = this.el.nativeElement.querySelector('.my-toolbar');
      this.renderer.setStyle(toolbarElem, 'backgroundColor', `rgba(51,51,51,${opacity})`);
    })

    this.scrollService.currentSection$.subscribe(section => {
      this.currentSection = section;
    });
  }

  navigateTo(page: string, elementId?: string): void {
    if (this.router.url.startsWith('/#') && page === "") {
      // We're already on the page, so we just want to scroll to the element
      this.scrollToElement(elementId);
    } else {
      this.router.navigate([page], { fragment: elementId });
    }
  }

  scrollToElement(elementId?: string | null): void {
    if (elementId) {
      const element = document.getElementById(elementId);
      if (element) {
        // Calculate the position you want to scroll to
        const yOffset = -72; // Your fixed header height here
        const y = element.getBoundingClientRect().top + window.scrollY + yOffset;

        // Now, use window.scrollTo with the calculated position and smooth scrolling
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    }
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const tolerance = 5; // Tolerance in pixels
    const pos = scrollTop + window.innerHeight;
    const max = document.documentElement.scrollHeight;
  
    // Check if scrolling down and close to the bottom
    if (scrollTop > this.lastScrollTop && pos + tolerance >= max) {
      this.highlightBookNowButton();
    }
  
    // Update last scroll position
    this.lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // For Mobile or negative scrolling
  }

  highlightBookNowButton() {
    const bookNowButton = document.querySelector('.book-now-navigation');
    if (bookNowButton) {
      bookNowButton.classList.add('highlighted-button');

      // Optional: Remove the highlight after some time
      setTimeout(() => {
        bookNowButton.classList.remove('highlighted-button');
      }, 4000); // Adjust time as needed
    }
  }
}
