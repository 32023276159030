<div *ngIf="!isConsentGranted; else playVideo" class="content-wrapper">
    <div class="consent-overlay">
        <p class="consent-text">Um dieses Video abspielen zu können, müssen Sie den <a target="_blank"
                href="https://www.youtube.com/intl/ALL_de/howyoutubeworks/user-settings/privacy/#overview">YouTube
                Datenschutzbestimmungen</a> zustimmen</p>
        <button mat-flat-button color="primary" class="consent-button" (click)="onConsent()">Ich stimme zu</button>
        <p class="consent-text"><a href="https://www.youtube.com/watch?v=Y54IhMrxnus&t=6s" target="_blank">Link zum
                Video auf YouTube</a></p>
    </div>
</div>

<ng-template #playVideo>
    <youtube-player #youtubePlayer id="youtubePlayer" videoId="Y54IhMrxnus" [playerVars]="playerVars">
    </youtube-player>
</ng-template>