<div class="full-width-true">
  <div class="max-width-container min-height-gigs tab-container">
    <div class="content-header">Termine</div>
    <mat-tab-group [color]="'primary'" mat-align-tabs="center" animationDuration="350ms">
      <mat-tab label="Anstehende">
        <div class="table-pagination-wrapper">
          <mat-table [dataSource]="upcomingDataSource">
            <ng-container matColumnDef="date">
              <mat-cell *matCellDef="let gig" class="gig-date"> {{ transformDate(gig.datetime) }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="title">
              <mat-cell *matCellDef="let gig" class="gig-title"> {{ extractTitle(gig) }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="venue">
              <mat-cell *matCellDef="let gig" class="gig-venue"> {{ extractVenue(gig) }} </mat-cell>
            </ng-container>

            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

          </mat-table>
          <mat-paginator [hidePageSize]="true" #upcomingPaginator [pageSizeOptions]="[10]"></mat-paginator>
        </div>
      </mat-tab>

      <!-- For Past Gigs -->
      <mat-tab label="Vergangene">
        <div class="table-pagination-wrapper">
          <mat-table [dataSource]="pastDataSource">

            <ng-container matColumnDef="date">
              <mat-cell *matCellDef="let gig" class="gig-date"> {{ transformDate(gig.datetime) }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="title">
              <mat-cell *matCellDef="let gig" class="gig-title"> {{ extractTitle(gig) }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="venue">
              <mat-cell *matCellDef="let gig" class="gig-venue"> {{ extractVenue(gig) }} </mat-cell>
            </ng-container>

            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

          </mat-table>
          <mat-paginator [hidePageSize]="true" #pastPaginator [pageSizeOptions]="[10]"></mat-paginator>
        </div>
      </mat-tab>

    </mat-tab-group>
  </div>
</div>
