import { Component } from '@angular/core';

export interface BandMember {
  name: string;
  instrument: string,
  image: string;
}

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent {
  fourMembers = [
    {
      name: 'Julian Merl',
      instrument: 'Gesang / Bass',
      image: 'assets/images/portrait/4.webp'
    },
    {
      name: 'Niels Hanselmann',
      instrument: 'Gitarre / Gesang',
      image: 'assets/images/portrait/6.webp'
    },
    {
      name: 'Dennis Link',
      instrument: 'Schlagzeug / Gesang',
      image: 'assets/images/portrait/2.webp'
    },
    {
      name: 'Jan Zimmermann',
      instrument: 'Keys',
      image: 'assets/images/portrait/3.webp'
    }
  ];
  
  fiveMembers = [
    {
      name: 'Luisa Klein',
      instrument: 'Gesang',
      image: 'assets/images/portrait/5.webp'
    }
  ];
  
  eightMembers = [
    {
      name: 'Pascal Hauns',
      instrument: 'Saxophon',
      image: 'assets/images/portrait/7.webp'
    },
    {
      name: 'Adrian Kirchenbauer',
      instrument: 'Posaune',
      image: 'assets/images/portrait/1.webp'
    },
    {
      name: 'Sascha Widman',
      instrument: 'Trompete',
      image: 'assets/images/portrait/8.webp'
    }
  ];
  
  
}
