<footer class="footer text-align-center">
  <img src="/assets/images/big/landscape/18.webp" class="footer-image">
  <div class="outer-container">
    <div class="footer-container">
      <app-contact></app-contact>
      <div>
        © 2008-2023 by <b>Sudden Inspiration</b> <br>
        <div class="footer-links">
          <button mat-flat-button class="footer-button" (click)="navigateToHome()">
            <a>Home</a>
          </button>
          <button mat-flat-button class="footer-button" routerLink="/form">
            <a>Booking</a>
          </button>
          <button mat-flat-button class="footer-button" routerLink="/impressum">
            <a>Impressum</a>
          </button>
        </div>
      </div>
      <app-social></app-social>
    </div>
  </div>
</footer>