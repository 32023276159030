import { Component, OnInit} from '@angular/core';

let apiLoaded = false;

@Component({
  selector: 'app-trailer-dialog',
  templateUrl: './trailer-dialog.component.html',
  styleUrls: ['./trailer-dialog.component.scss'],
})
export class TrailerDialogComponent {
  playerVars = {
    autoplay: 1,
    controls: 1,
    modestbranding: 1,
    rel: 0,
    showinfo: 0,
  };

  isConsentGranted = false;

  constructor() { }

  // Function to handle the consent
  onConsent() {
    this.isConsentGranted = true;
    if (!apiLoaded) {
      // This code loads the IFrame Player API code asynchronously, according to the instructions at
      // https://developers.google.com/youtube/iframe_api_reference#Getting_Started
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      document.body.appendChild(tag);
      apiLoaded = true;
    }
  }
}
