<div class="my-form-card">
  <div class="content-header">Buchungsanfrage</div>
  <div *ngIf="!isConsentGranted; else showForm" class="content-wrapper">
    <div class="consent-overlay">
      <p class="consent-text">Um dieses Formular zu schützen wird der Dienst Recaptcha von Google verwendet. Um die Funktion zu nutzen müssen Sie den 
        <a target="_blank" href="https://policies.google.com/privacy?hl=de">
          Datenschutzbestimmungen von Google</a>
          zustimmen.
      </p>
      <button mat-flat-button color="primary" class="consent-button" (click)="onConsent()">Ich stimme zu</button>
      <p class="consent-text">Schreiben Sie uns alternativ gerne eine E-Mail an <a href="mailto:booking@suddeninspiration.net">booking@suddeninspiration.net</a></p>
    </div>
  </div>
  <ng-template #showForm>
    <div *ngIf="notSent; else sent">
      <div class="my-form text-align-center">
        <p> Bitte füllen Sie das untenstehende Formular aus, um eine Auftrittsanfrage an uns zu senden.</p>

        <form (submit)="submit()">
          <mat-form-field appearance="fill">
            <mat-label>Termin</mat-label>
            <input matInput [min]="minDate" (click)="picker.open()" [matDatepicker]="picker" [(ngModel)]="date"
              required="true" name="date">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker disabled="false"></mat-datepicker>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Spielzeit in Stunden</mat-label>
            <input matInput type="number" min="1" [(ngModel)]="playingTime" required="false" name="playingTime"
              placeholder="4">
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Addresse der Location</mat-label>
            <textarea matInput [(ngModel)]="eventAddress" name="eventAddress"></textarea>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Kontaktperson</mat-label>
            <input matInput type="text" [(ngModel)]="contact" name="contact" required="true">
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Telefon</mat-label>
            <input matInput type="tel" [(ngModel)]="phone" name="phone">
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Email</mat-label>
            <input type="email" matInput [formControl]="emailFormControl" [errorStateMatcher]="matcher"
              placeholder="Ex. pat@example.com">
            <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
              Bitte geben Sie eine gültige E-Mail-Addresse an
            </mat-error>
            <mat-error *ngIf="emailFormControl.hasError('required')">
              Bitte geben Sie eine E-Mail-Adresse an
            </mat-error>
          </mat-form-field>

          <button type="submit" mat-raised-button class="submit-button" color="primary">
            Absenden
          </button>
          <button routerLink="/" mat-raised-button>
            Abbrechen
          </button>
        </form>
      </div>
    </div>
    <ng-template #sent>
      <div class="my-form text-align-center">
        <mat-progress-spinner [mode]="'indeterminate'" *ngIf="loading; else loadingFinished"></mat-progress-spinner>
        <ng-template #loadingFinished>
          <div *ngIf="error else noError">
            <div class="large-text">Error</div>
            <div>Es tut uns leid, aber es gab einen Fehler beim Verarbeiten Ihrer Daten. Bitte melden
              Sie sich manuell an <br>
              <a href="mailto:info@suddeninspiration.net">info@suddeninspiration.net</a><br>
              <button mat-raised-button routerLink="/" class="my-button" color="primary">
                Zurück zur Hauptseite
              </button>
            </div>
          </div>
          <ng-template #noError>
            <div class="large-text">Anfrage erfolgreich versendet!</div>
            <p>
              Vielen Dank für Ihre Anfrage! <br>
              Wir werden uns schnellstmöglich mit Ihnen in Verbindung setzen.
            </p>
            <button mat-flat-button routerLink="/" class="my-button" color="primary">
              Zurück zur Hauptseite
            </button>
          </ng-template>
        </ng-template>
      </div>
    </ng-template>
  </ng-template>
</div>