import { Component, OnInit } from '@angular/core';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-downloads',
  templateUrl: './downloads.component.html',
  styleUrls: ['./downloads.component.scss']
})
export class DownloadsComponent implements OnInit {

  staticUrl = environment.staticUrl;

  constructor() { }

  ngOnInit(): void {
  }

  files = [
    {
      title: 'Pressemappe',
      description: 'Unsere Pressemappe mit Pressetexten, Fotos und Kontaktinformationen.',
      url: 'static/Pressemappe-Sudden_Inspiration.pdf'
    },
    {
      title: 'Für Veranstalter',
      description: 'Alle Veranstalterinformationen und Materialien inklusive hochauflösenden Bildern.',
      url: 'static/Veranstaltermappe-Sudden_Inspiration.zip'
    },
    {
      title: 'Tecrider',
      description: 'Unser aktueller Tecrider.',
      url: 'static/Tecrider-Sudden_Inspiration.pdf'
    }
  ];

}
