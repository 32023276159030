import {Component, OnInit, ViewChild, inject} from '@angular/core';
import {GigsService} from '../services/gigs.service';
import {BehaviorSubject} from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

export interface Venue {
  country: string,
  city: string,
  latitude: string,
  name: string,
  location: string,
  region: string,
  longitude: string,
}

export interface Artist {
  thumb_url: string,
  mbid: string,
  facebook_page_url: string,
  image_url: string,
  tracker_count: number,
  tracking: any[],
  upcoming_event_count: number,
  url: string,
  support_url: string,
  name: string,
  options: {
    display_listen_unit: boolean,
  },
  links: Link[],
  artist_optin_show_phone_number: boolean,
  id: string,
}

export interface Link {
  type: string,
  url: string,
}

export interface Gig {
  venue: Venue,
  artist: Artist,
  starts_at: string,
  festival_datetime_display_rule: string,
  description: string,
  lineup: string[],
  festival_start_date: string,
  bandsintown_plus: boolean,
  title: string,
  artist_id: string,
  url: string,
  datetime_display_rule: string,
  datetime: string,
  on_sale_datetime: string,
  id: string,
  ends_at: string,
  festival_end_date: string
}

@Component({
  selector: 'app-gigs',
  templateUrl: './gigs.component.html',
  styleUrls: ['./gigs.component.scss']
})
export class GigsComponent implements OnInit {

  gigsService = inject(GigsService);
  loaded = false;
  pastLoaded = false;

  ngOnInit() {
    this.gigsService.getGigs().subscribe((gigs) => {
      this.upcomingDataSource.data = gigs;
      this.loaded = true;
    });
    this.gigsService.getPastGigs().subscribe((gigs) => {
      this.pastDataSource.data = gigs.reverse();
      this.pastLoaded = true;
    });
  }

  displayedColumns: string[] = ['date', 'title', 'venue'];
    
  upcomingDataSource = new MatTableDataSource<Gig>();
  pastDataSource = new MatTableDataSource<Gig>();

  @ViewChild('upcomingPaginator', { static: true }) upcomingPaginator!: MatPaginator;
  @ViewChild('pastPaginator', { static: true }) pastPaginator!: MatPaginator;

  ngAfterViewInit() {
      this.upcomingDataSource.paginator = this.upcomingPaginator;
      this.pastDataSource.paginator = this.pastPaginator;
  }

  transformDate(date: string): string {
    const innerDate = new Date(date);
    const options: Intl.DateTimeFormatOptions = {weekday: 'short', year: 'numeric', month: 'short', day: 'numeric'};
    return innerDate.toLocaleDateString(undefined, options);
  }

  extractTitle(gig: Gig): string {
    if (gig.title !== '') {
      return gig.title;
    } else {
      return gig.venue.name;
    }
  }

  extractVenue(gig: Gig): string {
    return gig.venue.city + ', ' + gig.venue.country;
  }
}
